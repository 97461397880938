 // export const MX_API_URL = 'http://ec2-52-91-192-144.compute-1.amazonaws.com'; // QC

 export const MX_API_URL = 'https://mobilityex.com'; // PRODUCTION
// export const MX_API_URL = 'http://localhost'

export const MX_COMPANY_SEARCH_URL = MX_API_URL + '/api/_search/services/company';
export const MX_COMPANY_SEARCH_RESULT_URL = MX_API_URL + '/api/_search/service-providerslist';
export const MX_COMPANY_SEARCH_FULLRESULT_URL = MX_API_URL + '/api/_search/service-providerslistfull';
export const MX_COMPANY_SEARCH_ADV_URL = MX_API_URL + '/api/subscription-control-iamtrusted';
export const MX_FMC_URL = MX_API_URL + '/api/generate-fmc/';
export const MX_CERT_URL = MX_API_URL + '/api/generate-certificate/';
export const MX_LOOKUPS = MX_API_URL + '/api/lookups/';
export const STATE_TYPE_ID = 51;


